import Link from "next/link";
import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { useTranslations } from "../hooks/useTranslations";

export default function Error404() {
  const { page_404 } = useTranslations();
  const { header, description, button } = page_404;
  return (
    <>
      <Container>
        <Row className="align-items-center">
          <Col lg={6} className="mx-auto px-lg-4 mt-5">
            <Card>
              <Card.Body className="p-lg-5">
                <h1 className="mb-4">{header}</h1>
                <p className="mb-5">{description}</p>
                <Link href="/claim">
                  <a className="btn-buy">{button}</a>
                </Link>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}
